import { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  Divider
} from '@mui/material';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CheckIcon from '@mui/icons-material/Check';
import EastIcon from '@mui/icons-material/East';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import dayjs from 'dayjs';
import Scrollbar from '../Scrollbar';

const iconsMap = {
  success: CheckIcon,
  info: ChatAltIcon,
  action: EastIcon,
  warning: CloseIcon,
  warningLight: WarningAmberIcon,
};

function NotificationsPopover({ handleClick, handleClickMultipleMarkRead, notifications, fetchMoreNotifications, fetchGoBackNotifications, setOpen, open }) {
  const anchorRef = useRef(null);

  // console.log(user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
          size="large"
        >
          <Badge
            color="error"
            badgeContent={notifications.filter((obj) => obj.notificationRead === false).length}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 520 } // change era 320, tambien puede ser '100%'
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notificaciones
          </Typography>
        </Box>
        <Divider />
        {notifications.length === 0
          ? (
            <Box sx={{ p: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                No hay notificaciones
              </Typography>
            </Box>
          )
          : (
            <>
              <Box sx={{ height: 400 }}>
                <Scrollbar>
                  <List disablePadding>
                    {notifications.map((notification) => {
                      const Icon = iconsMap[notification.notificationType];

                      return (
                        <ListItem
                          divider
                          key={notification.id}
                          sx={{ py: 0 }} // change no estaba este css
                        >
                          <ListItemAvatar>
                            {notification.notificationType === 'success' && (
                              <Avatar
                                sx={{
                                  backgroundColor: 'success.main',
                                  color: 'success.contrastText'
                                }}
                              >
                                <Icon fontSize="small" />
                              </Avatar>
                            )}
                            {notification.notificationType === 'warningLight' && (
                              <Avatar
                                sx={{
                                  backgroundColor: 'warningLight.main',
                                  color: 'warningLight.contrastText'
                                }}
                              >
                                <Icon fontSize="small" />
                              </Avatar>
                            )}
                            {notification.notificationType === 'warning' && (
                              <Avatar
                                sx={{
                                  backgroundColor: 'error.main',
                                  color: 'error.contrastText'
                                }}
                              >
                                <Icon fontSize="small" />
                              </Avatar>
                            )}
                            {notification.notificationType === 'info' && (
                              <Avatar
                                sx={{
                                  backgroundColor: 'info.main',
                                  color: 'info.contrastText'
                                }}
                              >
                                <Icon fontSize="small" />
                              </Avatar>
                            )}
                            {notification.notificationType === 'action' && (
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText'
                                }}
                              >
                                <Icon fontSize="small" />
                              </Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={!notification.notificationRead ? (
                              <Box>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  {notification.notificationTitle}
                                </Typography>
                                <Link
                                  color="textPrimary"
                                  sx={{ cursor: 'pointer' }}
                                  underline="always"
                                  variant="subtitle2"
                                  onClick={() => handleClick(notification.id)}
                                  component={RouterLink}
                                  to={notification.notificationLink}
                                  state={notification?.notificationState || null}
                                >
                                  {notification.notificationDescription}
                                </Link>
                              </Box>
                            ) : (
                              <Box>
                                <Typography
                                  color="textPrimary"
                                  variant="body2"
                                >
                                  {notification.notificationTitle}
                                </Typography>
                                <Link
                                  color="textPrimary"
                                  sx={{ cursor: 'pointer' }}
                                  underline="always"
                                  variant="body2"
                                  onClick={() => handleClick(notification.id)}
                                  component={RouterLink}
                                  to={notification.notificationLink}
                                  state={notification?.notificationState || null}
                                >
                                  {notification.notificationDescription}
                                </Link>
                              </Box>
                            )}
                            secondary={(
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                {dayjs(new Date(notification.createdAt.seconds * 1000 + notification.createdAt.nanoseconds / 1000000)).format('DD MMM YYYY, h:mm a')}
                              </Typography>
                            )}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Scrollbar>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1
                }}
              >
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => fetchGoBackNotifications()}
                  sx={{ mr: 4 }}
                >
                  Volver
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => fetchMoreNotifications()}
                  sx={{ mr: 4 }}
                >
                  Anteriores
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => handleClickMultipleMarkRead()}
                >
                  Marcar todas leídas
                </Button>
              </Box>
            </>
          )}
      </Popover>
    </>
  );
}

export default NotificationsPopover;
